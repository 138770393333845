




import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class PlzInput extends Vue {
  @Prop({ type: String })
  public value!: string;
  private fixSizedField($event: KeyboardEvent, lng: number): void {
    const val = $event && $event.target ? ($event.target as HTMLInputElement).value : "";
    if (typeof val !== "undefined" && (val.length >= lng || Number.isNaN(Number($event.key)))) {
      $event.preventDefault();
    }
  }

  private onInput(val: string): void {
    this.$emit("input", val);
  }
}
